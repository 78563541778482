import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import HeroMini from "../components/heroSmall"

const AboutUs = () => (
  <Layout>
    <Seo title="Ahout us" />
    <HeroMini title="About us"/>
    <main className="section has-text-black">
    <div className="container">
        <div className="columns content">
            <div className="column has-text-black">
            <p className="is-size-4 mb-3">BE FUTURE READY </p>
            <p className="is-size-6 mt-3">Our team of tech experts will help to refine your business processes, drive growth and prepare your business for the future. </p>
            <p className="is-size-6 mt-3">We understand that the world of IT products, cloud platforms and content management systems can feel overwhelming. We aim to make the complicated, simple, using our insider expertise to 
            carefully plan and roll-out cost-effective technology solutions tailored specifically to your 
            business – leaving you with time to focus on the work that matters to you. Whether that’s accounting, 
            aeronautics or conquering the world with your entrepreneurial genius is totally up to you. We’re just 
            here to help you choose the right digital technology tools to take on the challenge. </p>
            
            <p className="is-size-4 mb-3 mt-6">The Secret Sauce</p>

            <p className="is-size-6 mt-3">Fusion is built on our thirst for knowledge. We’re tech geeks at heart and love to spend 
                our time researching all the latest developments in the world of tech and business. This 
                is good news for you because it means that our team are at the cutting edge of innovation 
                in technology and business transformation practices. Bursting with industry knowledge 
                and expertise, our tech tag-team will design creative, practical solutions to help you 
                harness the latest technology within your organisation. </p>
            <p className="is-size-4 mb-3 mt-6">How we work</p>
            <p className="is-size-6 mt-3">We understand that your business is unique and our personal, flexible approach reflects 
                that. Our expert team takes time to assess your operation, understand its specific 
                challenges and identify key areas of improvement. We are tech guys (and girls!) but we 
                speak your language and aim to demystify the process of integrating tech into your 
                business. Where you see a problem, we see a challenge. We are delivery-focused, 
                working closely with you and your team to put efficient, turnkey solutions in place. 
                We communicate clearly and often, building a relationship that you know you can 
                rely on.</p>
            <p className="is-size-4 mb-3 mt-6">Fusion can help to: </p>
                <ul  className="has-text-black ml-6 mt-4 is-size-6">
                    <li>Transform your Business Processes</li>
                    <li>Drive business growth through automation and innovation</li>
                    <li>Enhance and simplify your IT infrastructure</li>
                    <li>Reduce your overall IT costs</li>
                    <li>Enable greater agility for your business</li>
                    <li>Bring effectiveness, reliability and scalability to your organisation</li>
                    <li>Reduce Risk for your business</li>
                </ul>
            </div>
        </div>
    </div>
    </main>
  </Layout>
)

export default AboutUs;
